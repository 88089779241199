import React from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import BackgroundImage from "gatsby-background-image";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { layoutOptions } from "../components/Content/RichText";
import * as Markdown from 'react-markdown';
import Layout from "../components/layout"
import SEO from "../components/seo"

const DocumentPage = ({ data, location }) => {
    console.log(data.contentfulDocumentPage)
    const content = data.contentfulDocumentPage;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const { metaDescription, metaImage, metaKeywords, pageTitle } = content
    const imagePath = `https:${metaImage.file.url}`
    const hero = content.heroCta

    return (
        <Layout page={location.pathname} props={content} title={pageTitle}>
            <SEO title={pageTitle} image={imagePath} />
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="image" content={`https:${metaImage.file.url}`} />
                <meta property="og:image" content={`https:${metaImage.file.url}`} />
                <meta name="twitter:image" content={`https:${metaImage.file.url}`} />
            </Helmet>
            <BackgroundImage
                Tag="div"
                fluid={hero.imageFile.localFile.childImageSharp.fluid}
                preserveStackingContext={true}
                className="uk-section uk-section-xlarge"
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: "rgba(58, 44, 68, 0.6)",
                    backgroundPosition: `center center`,
                    marginTop: "72px"
                }}>

                <div id={hero.slug} className="uk-container uk-padding uk-light">
                    <div className={`uk-flex uk-flex-middle uk-flex-left uk-height-1-1`}>
                        <div className="uk-padding-small uk-padding-remove-vertical uk-width-5-6@m">
                            <h1>{hero.headline}</h1>
                            {hero.subHeadline?.subHeadline ?
                                <Markdown
                                    source={hero.subHeadline.childMarkdownRemark.html}
                                    escapeHtml={false}
                                />
                                : null}
                        </div>
                    </div>
                </div>
            </BackgroundImage>

            {/* <article className="uk-margin-xxsmall">
                {richText}
            </article> */}
        </Layout>
    )
}

export default DocumentPage;

export const documentPageQuery = graphql`
    query documentPageQuery($slug: String) {
        contentfulDocumentPage(slug: {eq: $slug}) {
            slug
            canonicalLink
            metaDescription
            heroCta {
                headline
                subHeadline {
                    childMarkdownRemark {
                        html
                      }
                  subHeadline
                }
                imageFile {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                    }
                  }
                }
              }
            metaImage {
                file {
                    url
                }
                title
            }
            metaKeywords
            pageTitle
            content {
                json
            }
        }
    }`
